import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import { login, getNota } from '../../services/Brudam'
import { Chrono } from 'react-chrono';
import moment from 'moment';
import firebase from 'firebase';

export default function Rastreio() {
    const queryParams = new URLSearchParams(window.location.search);
    const [cpfCnpj] = useState(queryParams.get('d'))
    const [minuta] = useState(queryParams.get('m'))
    const [itens, setItens] = useState([])
    const [corpo, setCorpo] = useState({})
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        var usuarioRef = localStorage.getItem("usuario");
        if (usuarioRef) {
            const usuarioArray = JSON.parse(usuarioRef);
            setUsuario(usuarioArray)
            mensagemLoading('Buscando sua Encomenda...')
            login().then((res) => {
                getNota(res.data.access_key, minuta, cpfCnpj).then((doc) => {
                    if (doc.message === 'OK') {
                        if (doc.data[0].dados) {
                            console.log(doc.data[0])
                            setCorpo(doc.data[0])
                            const list = []
                            doc.data[0].dados.forEach((d) => {
                                list.push({
                                    date: moment(d.data),
                                    cardTitle: d.status === '101' ? d.obs : d.descricao,
                                    cardSubtitle: '\nAtualizado às ' + moment(d.data).format('HH:mm'),
                                    cardDetailedText: "Informações atualizada durante o processo",
                                })
                            })
                            setItens(list)
                            Swal.close();
                        }
                    }
                })
            })
        } else {
            window.location = '/'
        }

    }, {})

    function salvaConsulta() {
        if (!usuario.cadastro) {
            mensagemAlerta('Para salvar sua consulta você precisa se cadastrar em nossa base.')
            return
        }
        function mensagemAlerta(msg) {
            Swal.fire('Alerta', msg, 'warning')
        }
        mensagemLoading('Cadastrando consulta..')
        firebase.firestore().collection('rastreio').doc(usuario.id).collection('encomendas').where('nf', '==', minuta).get().then((sn) => {
            if (sn.size > 0) {
                mensagemAlerta('Esta consulta já está cadastrada')
            } else {
                firebase.firestore().collection('rastreio').doc(usuario.id).collection('encomendas').add({
                    nf: minuta,
                    cpfCnpj: cpfCnpj,
                    empresa: corpo.dados[0].razao_remetente,
                    status: corpo.dados[0].status === '101' ? corpo.dados[0].status.obs : corpo.dados[0].descricao,
                    data: corpo.dados[0].data
                }).then((res) => {
                    firebase.firestore().collection('rastreio').doc(usuario.id).collection('encomendas').doc(res.id).update({
                        id: res.id,
                    }).then(() => {
                        mensagemSucesso('Consulta cadastrada com sucesso! Agora voce pode visualizar em sua lista')
                    })
                })
            }
        })
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            {itens.length > 0 ?
                                <>
                                    <div className="container-xxl flex-grow-1 container-p-y">
                                        <div className="row">
                                            <div className="col-lg-12 mb-4 order-0">
                                                <div className="card">
                                                    <div className="d-flex align-items-end row">
                                                        <div className="col-sm-7">
                                                            <div className="card-body">
                                                                <h5 className="card-title text-primary">Documento  {corpo.documento}</h5>
                                                                <h5 className="card-title">{corpo.dados[0].razao_remetente}</h5>
                                                                <p className="mb-4">
                                                                    Previsão de entrega aproximadamente <b>dia {moment(corpo.prev_entrega).format('DD/MM/YYYY')}</b>
                                                                </p>
                                                                <a href="/home" className="btn btn-sm btn-outline-primary">Minha Home</a>
                                                                <a onClick={() => salvaConsulta()} style={{ marginLeft: 10 }} href="javascript:;" className="btn btn-sm btn-primary">Salvar esta consulta</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-5 text-center text-sm-left">
                                                            <div className="card-body pb-0 px-0 px-md-4">
                                                                <img src="../assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-xxl flex-grow-1 ">
                                        <div className="card">
                                            <h5 className="card-header">Histórico da Encomenda</h5>
                                            <Chrono
                                                items={itens}
                                                mode="VERTICAL_ALTERNATING"
                                                itemWidth={10}
                                                focusActiveItemOnLoad
                                                cardHeight={50}
                                                flipLayout
                                                outline
                                                timelinePointShape="circle"
                                            >
                                            </Chrono>
                                        </div>

                                    </div>
                                </>
                                :
                                null
                            }

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}