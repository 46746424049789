export const login = () => {
    return (async () => {
        try {
            const user = {
                usuario: "8cff3193b5670b8da2cc137b5752b873",
                senha: "31a3fbda3ad40d3d6b81e827228e301cdb7dcc6b0a56128c6faf8286ac837204"
            }
            const URL = "https://entregaja.brudam.com.br/api/v1/acesso/auth/login";
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(user)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}

export const getEmpresa = (token,empresa) => {
    const URL = "https://entregaja.brudam.com.br/api/v1/cadastro/empresas?cnpj="+empresa;
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token)
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

export const getMinuta = (token,minuta) => {
    const URL = "https://entregaja.brudam.com.br/api/v1/tracking/ocorrencias/minuta?codigo="+minuta;
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token)
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

export const getNota = (token,minuta,cpfCnpj) => {
    const URL = `https://entregaja.brudam.com.br/api/v1/tracking/ocorrencias/cnpj/nf?documento=${cpfCnpj}&numero=${minuta}`;
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token)
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

export const getColeta = (token,id) => {
    const URL = `https://entregaja.brudam.com.br/api/v1//operacional/consulta/coleta/${id}`;
    var headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token)
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

export const postFrete = (token,dados) => {
    return (async () => {
        try {
            const URL = "https://entregaja.brudam.com.br/api/v1/frete/cotacao/calcula"
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token)
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(dados)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}


export const cadastraColeta = (token,dados) => {
    return (async () => {
        try {
            const URL = "https://entregaja.brudam.com.br/api/v1/comercial/emissao/cotacao"
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token)
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(dados)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}

export const postPrazo = (token,dados) => {
    return (async () => {
        try {
            const URL = "https://entregaja.brudam.com.br/api/v1/prazos/entrega"
            var headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token)
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(dados)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}
