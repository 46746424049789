import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import InputMask from "react-input-mask";
import moment from 'moment';
import { encode } from 'js-base64';
import { cnpj } from 'cpf-cnpj-validator';

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [agente, setNomeAgente] = useState('')
    const [nome, setNome] = useState('')
    const [contato, setContato] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    function acessar() {
        if (!cnpj.isValid(cnpjEmpresa.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CNPJ inválido')
            return
        }
        if (!nomeEmpresa) {
            mensagemAlerta('Digite o nome da empresa')
            return
        }
        if (!agente) {
            mensagemAlerta('Digite o nome do Agente')
            return
        }
        if (!contato) {
            mensagemAlerta('Digite seu Contato')
            return
        }
        if (!email) {
            mensagemAlerta('Digite seu Email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua senha')
            return
        }
        if (!confirmaSenha) {
            mensagemAlerta('Confirme sua senha')
            return
        }
        if (senha != confirmaSenha) {
            mensagemAlerta('As senhas não são iguais')
            return
        }
        mensagemLoading('Cadastrando no sistema..')
        servicos.usuarios.where('email', '==', email).get().then((res) => {
            if (res.size > 0) {
                mensagemAlerta('Este email já está cadastrado em nosso sistema! Acesse agora em login')
            } else {
                servicos.auth.createUserWithEmailAndPassword(email, confirmaSenha).then(() => {
                    var usuario = firebase.auth().currentUser;
                    servicos.usuarios.doc(usuario.uid).set({
                        id: usuario.uid,
                        agente: agente,
                        cnpj: cnpjEmpresa,
                        contato: contato,
                        email: email,
                        nomeEmpresa: nomeEmpresa,
                        status: 'PENDENTE',
                        usuario: 'AGENTE',
                        comissao:parseInt(0),
                        dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
                        dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                        pass: encode('agente' + email + '' + usuario.uid + '' + cnpjEmpresa.replace(/[^a-z0-9]/gi, '') + confirmaSenha)
                    }).then(() => {
                        mensagemSucesso('Cadastro realizado com sucesso! Agora aguarde a análise de nossos consultores. Breve entraremos em contato')
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar o agente! Entre em contato com a administração ')
                    })
                }).catch((erro) => {
                    console.log(erro);
                    var errorCode = erro.code;
                    var errorMessage = erro.message;
                    if (errorCode == "auth/invalid-email") {
                        mensagemAlerta("O formato do Email é inválido!")
                    } else if (errorCode == "auth/email-already-in-use") {
                        mensagemAlerta("O email já está em uso pelo Entrega Já")
                    } else if (errorCode == "auth/weak-password") {
                        mensagemAlerta("Escolha uma senha mais forte!")
                    }
                })
                // localStorage.setItem("usuario", JSON.stringify({
                //     nome: nome,
                //     email: email,
                //     contato: contato
                // }));
                // window.location = '/home'
            }
        })

    }
    const handleCnpjEmpresa = (event) => {
        event.persist();
        setCnpjEmpresa(event.target.value);
    }
    const handleNomeEmpresa = (event) => {
        event.persist();
        setNomeEmpresa(event.target.value);
    }
    const handleAgente = (event) => {
        event.persist();
        setNomeAgente(event.target.value);
    }
    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(()=>{
            window.location = '/agente'
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            {/* Logo */}
                            <div className="app-brand justify-content-center">
                                <a href="/" className="app-brand-link gap-2">
                                    <img src="https://entregaja.com.br/assets/images/logo/logo.png" />
                                </a>
                            </div>
                            {/* /Logo */}
                            <h4 className="mb-2">Acesse com suas informações</h4>
                            <p className="mb-4">Sistema de cotação da Entrega Já</p>
                            <div id="formAuthentication" className="mb-3 row" >
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">CNPJ</label>
                                    <InputMask mask="99.999.999/9999-99" placeholder='000.000.000/0000-00' onChange={handleCnpjEmpresa} value={cnpjEmpresa} className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Empresa</label>
                                    <input type="text" onChange={handleNomeEmpresa} value={nomeEmpresa} className="form-control" id="email" name="email-username" placeholder="Nome Fantasia" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Agente</label>
                                    <input type="text" onChange={handleAgente} value={agente} className="form-control" id="email" name="email-username" placeholder="Seu nome" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Contato</label>
                                    <InputMask mask="(99) 99999-9999" placeholder='(00) 00000-0000' onChange={handleContato} value={contato} className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" onChange={handleEmail} value={email} className="form-control" id="email" name="email-username" placeholder="Email" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Senha</label>
                                    <input type="password" onChange={handleSenha} value={senha} className="form-control" id="email" name="email-username" placeholder="****" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Confirma Senha</label>
                                    <input type="password" onChange={handleConfirmaSenha} value={confirmaSenha} className="form-control" id="email" name="email-username" placeholder="*****" />
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => acessar()} className="btn btn-primary d-grid w-100" type="button">Cadastrar</button>
                                </div>
                            </div>
                            <p className="text-center">
                                <span>Possui Login? </span>
                                <a href="/">
                                    <span>Acesse agora</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}