import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import { login, getNota } from '../../services/Brudam'
import firebase from 'firebase';
import moment from 'moment';

export default function Login() {
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [minuta, setMinuta] = useState('')
    const [usuario, setUsuario] = useState({})
    const [rastreios, setRastreios] = useState([])

    useEffect(() => {
        var usuarioRef = localStorage.getItem("usuario");
        if (usuarioRef) {
            const usuarioArray = JSON.parse(usuarioRef);
            setUsuario(usuarioArray)
            if(usuarioArray.cadastro){
                mensagemLoading('Buscando suas encomendas...')
                firebase.firestore().collection('rastreio').doc(usuarioArray.id).collection('encomendas').get().then((res)=>{
                    const data = res.docs.map((d)=> d.data());
                    Swal.close()
                    setRastreios(data.sort((a, b) => {
                        return moment(b.data) - moment(a.data)
                        
                    }))
                })
            }
        } else {
            window.location = '/'
        }

    }, {})

    function buscaMinuta() {
        if (!cpfCnpj && !minuta) {
            mensagemAlerta("O CPF/CNPJ e Nota Fiscal não podem ser vazios")
        } else {
            login().then((res) => {
                console.log(res.data.access_key)
                getNota(res.data.access_key, minuta, cpfCnpj).then((doc) => {
                    if (doc.message === 'OK') {
                        if (doc.data[0].dados) {
                            window.location = '/rastreio?d=' + cpfCnpj + '&m=' + minuta
                        } else {
                            mensagemAlerta('Sem registros encontrados')
                        }
                    } else {
                        mensagemAlerta('Sem registros encontrados')
                    }
                })

            })
        }
    }

    const handleCpfCnpj = (event) => {
        event.persist();
        setCpfCnpj(event.target.value);
    }
    const handleMinua = (event) => {
        event.persist();
        setMinuta(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="modal fade" id="modalCadastro" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="exampleModalLabel2">Por que se cadastrar em nossa base de rastreio?</h4>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <h6>Veja vantagens de está conectado com a Entrega Já</h6>
                                            <p>1. Voce pode salvar suas consultas e ver um histórico de suas compras com as empresas que são nossas parceiras</p>
                                            <p>2. Você não terá que colocar todas as vezes as suas mesmas informações para rastrear suas encomendas. Apenas seu email e senha será o suficiente</p>
                                            <p>3. Mais detalhamento de suas consultas, assim voce consigará ver toda a trajetória de suas encomendas com um layout simples e intuitivo. </p>
                                            <p>4. Caso você tenha mais de uma encomenda a rastrear com a Entrega Já, você poderá ter uma visão de qual produto chegará primeiro. Usando nossa inteligência. </p>
                                            <p><b>Gostou? Que tal se cadastrar com a Entrega Já? </b></p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                                                Apenas continuar com minha consulta
                                            </button>
                                            <button type="button" data-bs-dismiss="modal" onClick={() => window.location = '/cadastro'} className="btn btn-primary">Quero me cadastrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="smallModal" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-sm" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel2">Suas informações </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col mb-3">
                                                    <label htmlFor="nameSmall" className="form-label">CPF/CNPJ</label>
                                                    <input type="text" onChange={handleCpfCnpj} value={cpfCnpj} id="nameSmall" className="form-control" placeholder="Somente números" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3">
                                                    <label htmlFor="nameSmall" className="form-label">Nota Fiscal</label>
                                                    <input type="text" onChange={handleMinua} value={minuta} id="nameSmall" className="form-control" placeholder="Nota fiscal da encomenda" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" data-bs-dismiss="modal" onClick={() => buscaMinuta()} className="btn btn-primary">Rastrear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 order-0">
                                        <div className="card">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-7">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-primary">Olá {usuario.nome} 🎉</h5>
                                                        {rastreios.length > 0 ?
                                                            <p className="mb-4">
                                                                Você possui <span className="fw-bold">{rastreios.length}</span> encomendas rastreadas pela Entrega Já
                                                            </p>
                                                            :
                                                            <p className="mb-4">
                                                                <span className="fw-bold"> Você não possui encomendas rastreadas pela Entrega Já</span>
                                                            </p>
                                                        }

                                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#smallModal" className="btn btn-sm btn-outline-primary">Rastrear uma encomenda</a>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 text-center text-sm-left">
                                                    <div className="card-body pb-0 px-0 px-md-4">
                                                        <img src="../assets/img/illustrations/man-with-laptop-light.png" height={140} alt="View Badge User" data-app-dark-img="illustrations/man-with-laptop-dark.png" data-app-light-img="illustrations/man-with-laptop-light.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {usuario.nome && !usuario.cadastro ?
                                <div className="container-xxl ">
                                    <div className="row">
                                        <div className="col-lg-12 mb-4 order-0">
                                            <div className="card">
                                                <div className="d-flex align-items-end row">
                                                    <div className="col-sm-12">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-primary">Que tal se cadastrar em nosso sistema de Rastreio?</h5>
                                                            
                                                            <p className="mb-12" >
                                                                <b>Gostaria de se cadastrar em nossa base de rastreio? Clique abaixo e saiba o que pode facilitar em suas consultas.</b>
                                                            </p>
                                                            <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalCadastro" className="btn btn-sm btn-outline-primary">Saiba Mais</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="container-xxl flex-grow-1 ">
                                <div className="card">
                                    <h5 className="card-header">Seus Rastreios</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Empresa</th>
                                                    <th>NF</th>
                                                    <th>Atualização</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {rastreios.map((rast) =>
                                                    <tr>
                                                        <td><i className="fab fa-angular fa-lg text-danger me-3" /> <strong>{rast.empresa}</strong></td>
                                                        <td>{rast.nf}</td>
                                                        <td>{moment(rast.data).format('DD/MM/YYYY - HH:mm')}</td>
                                                        <td>{rast.status}</td>
                                                        <td>
                                                            <button onClick={()=> window.location = '/rastreio?d=' + rast.cpfCnpj + '&m=' + rast.nf} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>

                                                        </td>
                                                    </tr>
                                                )}
                                                {rastreios.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem rastreios cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}