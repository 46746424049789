import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';

export default function ListaEmpresasAgente() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        empresas: firebase.firestore().collection('empresas'),
    })
    const [mes, setMes] = useState(moment().format('MM'))
    const [ano, setAno] = useState(moment().format('YYYY'))
    const [usuario, setUsuario] = useState({})
    const [empresas, setEmpresas] = useState([])

    useEffect(() => {
        mensagemLoading('Carregando Empresas')
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                servicos.empresas
                    .where('agente.id', '==', user.uid)
                    .get().then((res) => {
                        const data = res.docs.map((d) => d.data())
                        setEmpresas(data)
                        Swal.close()
                    })
            }
        })

    }, {})

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                
                                <div className="card">
                                    <h5 className="card-header">Clientes</h5>
                                    
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>CNPJ</th>
                                                    <th>Representante</th>
                                                    <th>Contato</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {empresas.map((cotacao) =>
                                                    <tr key={cotacao.id}>
                                                        <td>{cotacao.nome}</td>
                                                        <td>{cotacao.cnpjEmpresa}</td>
                                                        <td>{cotacao.representante}</td>
                                                        <td>{cotacao.whatsapp}</td>
                                                        <td>{cotacao.email}</td>
                                                    </tr>
                                                )}
                                                {empresas.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem empresas cadastradas</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}