const token = 'e5afef5b-7769-4f20-ac0c-3ec3caffedd7';

export const getEmpresa = (cnpj) => {
    return (async () => {
        try {
            // const URL = "http://127.0.0.1:5001/entrega-ja-a942a/us-central1/app/buscaEmpresa/"+cnpj;
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/buscaEmpresa/"+cnpj;
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            // headers.append("Authorization","Token "+token)
            // headers.append("Access-Control-Allow-Origin", "*");
            const response = await fetch(URL, {
                method: "GET",
                headers: headers,
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}

export const criaEmpresa = (empresa) => {
    return (async () => {
        try {
            // const URL = "http://127.0.0.1:5001/entrega-ja-a942a/us-central1/app/cadastraEmpresa";
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/cadastraEmpresa";
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            // headers.append("Authorization","Token "+token)
            // headers.append("Access-Control-Allow-Origin", "*");
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(empresa)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}
export const geraCotacaoNegocio = (empresa, negocio) => {
    return (async () => {
        try {
            // const URL = "http://127.0.0.1:5001/entrega-ja-a942a/us-central1/app/geraLead/"+empresa;
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/geraLead/"+empresa
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            // headers.append("Authorization","Token "+token)
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(negocio)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}
export const atualizaCotacaoNegocio = (id, negocio) => {
    return (async () => {
        try {
            console.log(negocio)
            // const URL = "http://127.0.0.1:5001/entrega-ja-a942a/us-central1/app/atualizaLead/"+id;
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/atualizaLead/"+id
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            // headers.append("Authorization","Token "+token)
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(negocio)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}
export const geraTask = (funil, negocio) => {
    return (async () => {
        try {
            console.log(negocio)
            // const URL = "http://127.0.0.1:5001/entrega-ja-a942a/us-central1/app/geraTask/"+funil;
            const URL = "https://us-central1-entrega-ja-a942a.cloudfunctions.net/app/geraTask/"+funil
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            // headers.append("Authorization","Token "+token)
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(negocio)
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}