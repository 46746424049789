import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import InputMask from "react-input-mask";
import moment from 'moment';
import { encode } from 'js-base64';
import { cnpj } from 'cpf-cnpj-validator';
import { buscaEndereco } from '../../../services/Localidades'
import CurrencyInput from 'react-currency-input';
import { login, postFrete } from '../../../services/Brudam'

export default function CotacaoAgente() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacoes: firebase.firestore().collection('cotacao'),
    })
    const [agente, setAgente] = useState({})
    const [usuario, setUsuario] = useState({})
    const [cubagem, setCubagem] = useState(0)
    const [pesoCubado, setPesoCubado] = useState(0)

    // Cotação
    const [cotacao, setCotacao] = useState({})
    const [produtos, setProdutos] = useState([])
    const [valorFinal, setValorFinal] = useState(0)
    const [comissaoFinal, setComissaoFinal] = useState(0)
    const [minuta, setMinuta] = useState(0)
    const [observacao, setObservacao] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                servicos.cotacoes.doc(id).get().then((res) => {
                    setCotacao(res.data())
                    let cub = 0
                    let pesCub = 0
                    servicos.cotacoes.doc(id).collection('produtos').get().then((prod) => {
                        const data = prod.docs.map((d) => d.data())
                        setProdutos(data)
                        data.forEach((d) => {
                            cub = cub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom))
                            pesCub = pesCub + (parseFloat(d.dAlt) * parseFloat(d.dLar) * parseFloat(d.dCom) * 300) * d.qVol
                        })
                        setCubagem(parseFloat(cub))
                        setPesoCubado(parseFloat(pesCub))
                    })
                    if (res.data().agente) {
                        setValorFinal(res.data().valorTotal)
                        setComissaoFinal(res.data().valorComissao)
                        servicos.usuarios.doc(res.data().agente.id).get().then((res) => {
                            setAgente(res.data())
                        })
                    }
                })
            }
        })

    }, [])

    function confirmaCotacao() {
        if (!minuta) {
            mensagemAlerta('Por favor digite a minuta de acompanhamento')
            return
        }
        if (!observacao) {
            mensagemAlerta('Por favor descreva uma observação para o Agente')
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja confirmar a Cotação ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Confirmando cotação')
                servicos.cotacoes.doc(cotacao.id).update({
                    valorFinal: parseFloat(valorFinal),
                    comissaoFinal: parseFloat(comissaoFinal),
                    minuta: minuta,
                    status: 'FINALIZADO',
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then(() => {
                    servicos.cotacoes.doc(cotacao.id).collection('historicos').add({
                        acao:'Cotação Confirmada',
                        descricao:'Cotação confirmada e iniciará o processo interno',
                        observacao:observacao,
                        status:'FINALIZADO',
                        dataCadastro:moment().format('YYYY-MM-DD HH:mm'),
                        usuario:{
                            id:usuario.id,
                            nome:usuario.nome,
                            email:usuario.email
                        },
                    }).then(()=>{
                        mensagemSucesso('Cotação confirmada com sucesso!')
                    })
                    
                })
            }
        });

    }

    function cancelarCotacao() {
        if (!observacao) {
            mensagemAlerta('Por favor descreva uma observação para o Agente')
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar a Cotação ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Cancelando cotação')
                servicos.cotacoes.doc(cotacao.id).update({
                    status: 'CANCELADO',
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
                }).then(() => {
                    servicos.cotacoes.doc(cotacao.id).collection('historicos').add({
                        acao:'Cotação Cancelada',
                        descricao:'Cotação cancelada pelo consultor',
                        observacao:observacao,
                        status:'CANCELADO',
                        dataCadastro:moment().format('YYYY-MM-DD HH:mm'),
                        usuario:{
                            id:usuario.id,
                            nome:usuario.nome,
                            email:usuario.email
                        },
                    }).then(()=>{
                        mensagemSucesso('Cotação cancelada com sucesso!')
                    })
                    
                })
            }
        });

    }


    function handleValorFinal(event, maskedvalue, floatvalue) {
        setValorFinal(maskedvalue)
    }
    function handleComissaoFinal(event, maskedvalue, floatvalue) {
        setComissaoFinal(maskedvalue)
    }
    const handleMinuta = (event) => {
        event.persist();
        setMinuta(event.target.value);
    }
    const handleObseravacao = (event) => {
        event.persist();
        setObservacao(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    {cotacao.id ?
                                        <>
                                            <div className="col-md-12">
                                                <div className="card mb-4">
                                                    <h5 className="card-header">Cotação #{cotacao.cotacao}</h5>
                                                    {/* Account */}
                                                    {agente.id ?
                                                        <div className="card-body row">
                                                            <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-2">
                                                                <img src={agente.id && agente.avatarEmpresa ? agente.avatarEmpresa : 'https://png.pngtree.com/png-vector/20190930/ourmid/pngtree-building-icon-isolated-on-abstract-background-png-image_1763153.jpg'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                            </div>
                                                            <div className=" align-items-start align-items-sm-center gap-4 col-md-4">
                                                                <h3>{agente.agente}</h3>
                                                                <p>{agente.nomeEmpresa} <br />{agente.cnpj}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="card-body row">
                                                            <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-2">
                                                                <img src={usuario.id && usuario.avatarEmpresa ? usuario.avatarEmpresa : 'https://png.pngtree.com/png-vector/20190930/ourmid/pngtree-building-icon-isolated-on-abstract-background-png-image_1763153.jpg'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                            </div>
                                                            <div className=" align-items-start align-items-sm-center gap-4 col-md-4">
                                                                <h3>Cotação Pública</h3>
                                                            </div>
                                                        </div>
                                                    }

                                                    <hr className="my-0" />
                                                    <div className="card-body">
                                                        <div onsubmit="return false">
                                                            <div className="row">
                                                                <div class="mb-3 col-md-12" >
                                                                    <h5>Dados da Empresa</h5>
                                                                </div>
                                                                <div class="mb-3 col-md-3">
                                                                    <label class="form-label" for="basic-icon-default-fullname">CNPJ</label>
                                                                    <p>{cotacao.empresa.cnpj}</p>
                                                                </div>
                                                                <div class="mb-3 col-md-4">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Nome da Empresa</label>
                                                                    <p>{cotacao.empresa.nome}</p>
                                                                </div>
                                                                <div class="mb-3 col-md-4">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Nome do Representante</label>
                                                                    <p>{cotacao.empresa.representante}</p>
                                                                </div>
                                                                <div class="mb-3 col-md-2">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Whatsapp</label>
                                                                    <p>{cotacao.empresa.whatsapp}</p>
                                                                </div>
                                                                <div class="mb-3 col-md-5">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                                                    <p>{cotacao.empresa.email}</p>
                                                                </div>
                                                                <div class="mb-3 col-md-5">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                                                    <p>{cotacao.agente ? cotacao.agente.agente : ''}</p>
                                                                </div>

                                                            </div>
                                                            <hr className="my-4 " />
                                                            <div className="row" style={{ marginTop: 20 }}>
                                                                <div class="mb-3 col-md-12" >
                                                                    <h5>Origem e Destino</h5>
                                                                </div>
                                                                <div class="mb-3 col-md-12">
                                                                    <h5 style={{ marginTop: 20 }} class="mb-0">Origem</h5>
                                                                </div>
                                                                <p>
                                                                    {cotacao.origem.logradouro}, {cotacao.origem.bairro}<br />
                                                                    {cotacao.origem.localidade} - {cotacao.origem.uf} - CEP {cotacao.origem.cep}<br />
                                                                    {cotacao.origem.complemento} - {cotacao.origem.referencia}

                                                                </p>
                                                                <div class="mb-3 col-md-12">
                                                                    <h5 style={{ marginTop: 20 }} class="mb-0">Destino</h5>
                                                                </div>
                                                                <p>
                                                                    {cotacao.destino.logradouro}, {cotacao.destino.bairro}<br />
                                                                    {cotacao.destino.localidade} - {cotacao.destino.uf} - CEP {cotacao.destino.cep}<br />
                                                                    {cotacao.destino.complemento} - {cotacao.destino.referencia}

                                                                </p>

                                                            </div>
                                                            <hr className="my-4 " />
                                                            <div className="row" style={{ marginTop: 20 }}>
                                                                <div class="mb-3 col-md-12" >
                                                                    <h5>Volumes</h5>
                                                                </div>
                                                                <div className='mb-3 col-md-12'>
                                                                    <table class="table" style={{ marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Produto</th>
                                                                                <th>medidas(Compr x Larg x Alt)</th>
                                                                                <th>Peso</th>
                                                                                <th>Qtd</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="table-border-bottom-0">
                                                                            {produtos.map((p, index) =>
                                                                                <tr>
                                                                                    <td>{p.nome}</td>
                                                                                    <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                                                    <td>{p.pBru} kg</td>
                                                                                    <td>{p.qVol}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                    <label >Cubagem</label>
                                                                    <p>{cubagem.toFixed(3)} m³</p>
                                                                </div>
                                                                <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                    <label >Peso Cubado</label>
                                                                    <p>{pesoCubado.toFixed(2)} kg</p>
                                                                </div>

                                                            </div>
                                                            <hr className="my-4 " />
                                                            <div className="row" style={{ marginTop: 20 }}>
                                                                <div class="mb-3 col-md-12" >
                                                                    <h5>Serviços e valores</h5>
                                                                </div>
                                                                {cotacao.servico == '080' ?
                                                                    <div className='col-md-3'>
                                                                        <label style={{ marginTop: 20 }}>Serviço</label>
                                                                        <p><b>Entrega Convencional</b></p>
                                                                    </div>
                                                                    : cotacao.servico == '081' ?
                                                                        <>
                                                                            <div className='col-md-4'>
                                                                                <label style={{ marginTop: 20 }}>Serviço</label>
                                                                                <p><b>Entrega Convencional</b></p>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                <div className='col-md-3'>
                                                                    <label style={{ marginTop: 20 }}>Valor da Nota</label>
                                                                    <p><b>{(parseFloat(cotacao.valorNota)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label style={{ marginTop: 20 }}>Pagamento Frete</label>
                                                                    <p><b>{cotacao.pagamentoFrete === 'ORIGEM' ? 'Pagamento na Origem' : 'Pagamento na Entrega'}</b></p>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <label style={{ marginTop: 20 }}>Entrega</label>
                                                                    <p><b>{cotacao.entrega === 'BASE' ? 'Retirada na Base' : 'Entrega a Domicílio'}</b></p>
                                                                </div>
                                                            </div>

                                                            {cotacao ?
                                                                <>
                                                                    <hr className="my-4 " />
                                                                    <div className="row" style={{ marginTop: 20 }}>
                                                                        <div class="mb-3 col-md-12" >
                                                                            <h5>Resultado</h5>
                                                                        </div>
                                                                        <div class="mb-3 col-md-12">
                                                                            <hr />
                                                                            <h5 style={{ marginTop: 20 }} class="mb-0"><b>Resultado da Cotação</b></h5>
                                                                        </div>
                                                                        <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                            Prazo Previsto de Entrega
                                                                        </div>
                                                                        <div className='col-md-3'>
                                                                            De {cotacao.prazo - 2} á {cotacao.prazo} dias úteis
                                                                        </div>
                                                                        <div style={{ marginTop: 30 }} className='col-md-9'>
                                                                            <b style={{ fontSize: 20 }}>Valor Total do Frete</b>
                                                                        </div>
                                                                        <div style={{ marginTop: 20 }} className='col-md-3'>
                                                                            <b style={{ fontSize: 30 }}>{(parseFloat(cotacao.valorTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                        </div>
                                                                        <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                            <b style={{ fontSize: 20 }}>Valor Comissão</b>
                                                                        </div>
                                                                        <div style={{ marginTop: 10 }} className='col-md-3'>
                                                                            <b style={{ fontSize: 30 }}>{((parseFloat(cotacao.valorTotal) * parseInt(cotacao.comissao) / 100)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* /Account */}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="card mb-4">
                                                    <h5 className="card-header">Ação</h5>
                                                    <div className="card-body">
                                                        <div onsubmit="return false">
                                                            <div className="row">
                                                                <div class="mb-3 col-md-3">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Valor Final</label>
                                                                    <div class="input-group input-group-merge">
                                                                        <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                            class="bx bx-money"></i></span>
                                                                        <CurrencyInput className="form-control" prefix="R$ " value={valorFinal} onChange={handleValorFinal} decimalSeparator="," thousandSeparator="." />
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 col-md-3">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Comissão Final</label>
                                                                    <div class="input-group input-group-merge">
                                                                        <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                            class="bx bx-money"></i></span>
                                                                        <CurrencyInput className="form-control" prefix="R$ " value={comissaoFinal} onChange={handleComissaoFinal} decimalSeparator="," thousandSeparator="." />
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 col-md-2">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Minuta</label>
                                                                    <input value={minuta} min={0} onChange={handleMinuta} type="number" class="form-control" />
                                                                </div>
                                                                <div class="mb-3 col-md-12">
                                                                    <label class="form-label" for="basic-icon-default-fullname">Observação</label>
                                                                    <textarea value={observacao} min={0} onChange={handleObseravacao} type="number" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                <button onClick={() => confirmaCotacao()} type="submit" className="btn btn-success me-2">Confirmar Cotação</button>
                                                                <button onClick={() => cancelarCotacao()} type="submit" className="btn btn-danger me-2">Cancelar Cotação</button>
                                                                <button onClick={() => window.history.back()} type="reset" className="btn btn-outline-secondary">Voltar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* /Account */}
                                                </div>
                                            </div>
                                        </>

                                        :
                                        null
                                    }

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}