import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import { login, getNota } from '../../../services/Brudam'
import firebase from 'firebase';

export default function Admin() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacao: firebase.firestore().collection('cotacao'),
    })
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [minuta, setMinuta] = useState('')
    const [usuario, setUsuario] = useState({})
    const [cotacoes, setCotacoes] = useState([])
    const [agentes, setAgentes] = useState([])

    useEffect(() => {
        mensagemLoading('Buscando Agentes..')
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                servicos.usuarios.where('usuario', '==', 'AGENTE').get().then((res) => {
                    const data = res.docs.map((d) => d.data())
                    setAgentes(data)
                    Swal.close()
                })
            }
        })

    }, {})

    function buscaMinuta() {
        if (!cpfCnpj && !minuta) {
            mensagemAlerta("O CPF/CNPJ e Nota Fiscal não podem ser vazios")
        } else {
            login().then((res) => {
                console.log(res.data.access_key)
                getNota(res.data.access_key, minuta, cpfCnpj).then((doc) => {
                    if (doc.message === 'OK') {
                        if (doc.data[0].dados) {
                            window.location = '/rastreio?d=' + cpfCnpj + '&m=' + minuta
                        } else {
                            mensagemAlerta('Sem registros encontrados')
                        }
                    } else {
                        mensagemAlerta('Sem registros encontrados')
                    }
                })

            })
        }
    }

    const handleCpfCnpj = (event) => {
        event.persist();
        setCpfCnpj(event.target.value);
    }
    const handleMinua = (event) => {
        event.persist();
        setMinuta(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{agentes.filter((d) => d.status === 'ATIVO').length}</h4>
                                                </div>
                                                <p className="mb-1">Ativos</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-warning h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-warning"><i className="bx bx-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{agentes.filter((d) => d.status === 'PENDENTE').length}</h4>
                                                </div>
                                                <p className="mb-1">Pendentes</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-danger h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-danger"><i className="bx bx-file" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{agentes.filter((d) => d.status === 'INATIVO').length}</h4>
                                                </div>
                                                <p className="mb-1">Inativos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-xxl flex-grow-1 ">
                                <div className="card">
                                    <h5 className="card-header">Agentes </h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Agente</th>
                                                    <th>CNPJ</th>
                                                    <th>Empresa</th>
                                                    <th>Status</th>
                                                    <th>Cotações</th>
                                                    <th>Detalhe</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {agentes.map((agente) =>
                                                    <tr key={agente.id}>
                                                        <td>{agente.agente}</td>
                                                        <td>{agente.cnpj}</td>
                                                        <td>{agente.nomeEmpresa}</td>
                                                        <td>
                                                            {agente.status === 'PENDENTE' ?
                                                                <span class="badge bg-label-warning me-1">Pendente</span>
                                                                : agente.status === 'ATIVO' ?
                                                                    <span class="badge bg-label-success me-1">Ativo</span>
                                                                    :
                                                                    <span class="badge bg-label-danger me-1">Inativo</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button onClick={() => window.location = '/cotacoesAgente?a=' + agente.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => window.location = '/detalheAgente?a=' + agente.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {agentes.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem agentes para validar</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}