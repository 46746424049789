import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import InputMask from "react-input-mask";
import moment from 'moment';
import { encode } from 'js-base64';
import { cnpj } from 'cpf-cnpj-validator';
import { buscaEndereco } from '../../../services/Localidades'

export default function DetalheAgente() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('a'))

    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [usuario, setUsuario] = useState({})
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState(usuario.id ? usuario.agente : '')
    const [agente, setNomeAgente] = useState(usuario.id ? usuario.agente : '')
    const [contato, setContato] = useState('')
    const [comissao, setComissao] = useState(0)
    const [status, setStatus] = useState('')

    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [referencia, setReferencia] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatarEmpresa, setAvatarEmpresa] = useState('');
    const [uploadEmpresa, setUploadEmpresa] = useState('');
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                if (id) {
                    servicos.usuarios.doc(id).get().then((res) => {
                        setAvatarEmpresa(res.data().avatarEmpresa)
                        setAvatar(res.data().avatar)
                        setUsuario(res.data())
                        setNomeAgente(res.data().agente)
                        setNomeEmpresa(res.data().nomeEmpresa)
                        setContato(res.data().contato)
                        setCep(res.data().endereco.cep)
                        setEndereco(res.data().endereco.endereco)
                        setBairro(res.data().endereco.bairro)
                        setCidade(res.data().endereco.cidade)
                        setUf(res.data().endereco.uf)
                        setComissao(res.data().comissao)
                        setStatus(res.data().status)
                    })
                } else {
                    servicos.usuarios.doc(user.uid).get().then((res) => {
                        setAvatarEmpresa(res.data().avatarEmpresa)
                        setAvatar(res.data().avatar)
                        setUsuario(res.data())
                        setNomeAgente(res.data().agente)
                        setNomeEmpresa(res.data().nomeEmpresa)
                        setContato(res.data().contato)
                        setCep(res.data().endereco.cep)
                        setEndereco(res.data().endereco.endereco)
                        setBairro(res.data().endereco.bairro)
                        setCidade(res.data().endereco.cidade)
                        setUf(res.data().endereco.uf)
                        setComissao(res.data().comissao)
                        setStatus(res.data().status)
                    })
                }

            }
        })

    }, [])


    function cadastraAvatarEmpresa(id) {
        Swal.close();
        mensagemLoading('Enviando a imagem da logomarca...')
        var uploadTask = firebase.storage().ref().child('agente/empresa' + id).put(avatarEmpresa[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatarEmpresa: downloadURL
                }).then(() => {
                    if (upload) {
                        cadastraAvatar(id)
                    } else {
                        mensagemSucesso('Cadastro de cliente realizado com sucesso!')
                    }
                })
            });
        });
    }

    function cadastraAvatar(id) {
        Swal.close();
        mensagemLoading('Enviando a imagem do perfil...')
        var uploadTask = firebase.storage().ref().child('agente/perfil' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    mensagemSucesso('Usuário atualizado com sucesso!')
                })
            });
        });
    }

    function atualizar() {
        if (!agente) {
            mensagemAlerta('Digite o nome do Agente')
            return
        }
        if (!contato) {
            mensagemAlerta('Digite seu Contato')
            return
        }
        mensagemLoading('Cadastrando no sistema..')
        servicos.usuarios.doc(usuario.id).update({
            agente: agente,
            contato: contato,
            endereco: {
                cep: cep,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                cidade: cidade,
                uf: uf,
                complemento: complemento,
                referencia: referencia
            },
            status:status,
            comissao:parseFloat(comissao),
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm')
        }).then(() => {
            if (uploadEmpresa) {
                cadastraAvatarEmpresa(usuario.id)
            } else if (upload) {
                cadastraAvatar(usuario.id)
            } else {
                mensagemSucesso('Usuário atualizado com sucesso!')
            }
        })

    }
    const handleAvatarEmpresa = (event) => {
        event.persist();
        setAvatarEmpresa(event.target.files)
        setUploadEmpresa(URL.createObjectURL(event.target.files[0]))
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleAgente = (event) => {
        event.persist();
        setNomeAgente(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handleComissao = (event) => {
        event.persist();
        setComissao(event.target.value);
    }
    const handleStatus = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Aguarde.. carregando endereço')
            buscaEndereco(event.target.value).then((doc) => {
                if (doc.erro === true) {
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    Swal.close();
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }

    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBirro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleReferencia = (event) => {
        event.persist();
        setReferencia(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    {usuario.id ?
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <h5 className="card-header">{usuario.agente}</h5>
                                                {/* Account */}
                                                <div className="card-body row">
                                                    <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-6">
                                                        <img src={uploadEmpresa ? uploadEmpresa : avatarEmpresa ? avatarEmpresa : 'https://png.pngtree.com/png-vector/20190930/ourmid/pngtree-building-icon-isolated-on-abstract-background-png-image_1763153.jpg'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                        <div className="button-wrapper">
                                                            <label htmlFor="uploadEmpresa" className="btn btn-primary me-2 mb-4" tabIndex={0}>
                                                                <span className="d-none d-sm-block">Carregar Logomarca</span>
                                                                <i className="bx bx-upload d-block d-sm-none" />
                                                                <input onChange={handleAvatarEmpresa} type="file" id="uploadEmpresa" className="account-file-input" hidden accept="image/png, image/jpeg" />
                                                            </label>
                                                            <p className="text-muted mb-0">Formatos PNG, JPEG, JPG</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-6">
                                                        <img src={upload ? upload : avatar ? avatar : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBgWJzNCd62qphgFudNiPEJzPT2PEfb_W9mcTVJ-RsmL1_FPYUP7SHDEzKX8HOkBrRz3g&usqp=CAU'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                        <div className="button-wrapper">
                                                            <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex={0}>
                                                                <span className="d-none d-sm-block">Carregar Perfil</span>
                                                                <i className="bx bx-upload d-block d-sm-none" />
                                                                <input onChange={handleAvatar} type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" />
                                                            </label>
                                                            <p className="text-muted mb-0">Formatos PNG, JPEG, JPG</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <div className="card-body">
                                                    <div onsubmit="return false">
                                                        <div className="row">
                                                            <div className="mb-3 col-md-5">
                                                                <label htmlFor="firstName" className="form-label">Agente</label>
                                                                <input value={agente} onChange={handleAgente} className="form-control" type="text" id="firstName" name="firstName" autofocus />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label htmlFor="lastName" className="form-label">Empresa</label>
                                                                <input value={nomeEmpresa} disabled className="form-control" type="text" name="lastName" id="lastName" defaultValue="Doe" />
                                                            </div>
                                                            <div className="mb-3 col-md-3">
                                                                <label htmlFor="email" className="form-label">CNPJ</label>
                                                                <input disabled value={usuario.cnpj} className="form-control" type="text" id="email" name="email" defaultValue="john.doe@example.com" placeholder="john.doe@example.com" />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label className="form-label" htmlFor="phoneNumber">Contato</label>
                                                                <div className="input-group input-group-merge">
                                                                    <span className="input-group-text">BR (+55)</span>
                                                                    <input value={contato} onChange={handleContato} type="text" id="phoneNumber" name="phoneNumber" className="form-control" placeholder="202 555 0111" />
                                                                </div>
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label className="form-label" htmlFor="phoneNumber">Email</label>
                                                                <div className="input-group input-group-merge">
                                                                    <input disabled value={usuario.email} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="mb-3 col-md-2">
                                                                <label htmlFor="email" className="form-label">Comissão(%)</label>
                                                                {id ?
                                                                    <input onChange={handleComissao} value={comissao} className="form-control" type="number" />
                                                                    :
                                                                    <input disabled value={usuario.comissao + '%'} className="form-control" type="text" />
                                                                }

                                                            </div>
                                                            <div className="mb-3 col-md-12">

                                                            </div>
                                                            <div className="mb-3 col-md-2">
                                                                <label htmlFor="state" className="form-label">CEP</label>
                                                                <input value={cep} onChange={handleCep} className="form-control" type="text" id="state" name="state" />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label htmlFor="zipCode" className="form-label">Endereço</label>
                                                                <input value={endereco} onChange={handleEndereco} type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label htmlFor="zipCode" className="form-label">Bairro</label>
                                                                <input value={bairro} onChange={handleBirro} type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-2">
                                                                <label htmlFor="zipCode" className="form-label">N</label>
                                                                <input value={numero} onChange={handleNumero} type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label htmlFor="zipCode" className="form-label">Cidade</label>
                                                                <input value={cidade} onChange={handleCidade} disabled type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-1">
                                                                <label htmlFor="zipCode" className="form-label">UF</label>
                                                                <input value={uf} onChange={handleUf} disabled type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-4">
                                                                <label htmlFor="zipCode" className="form-label">Complemento</label>
                                                                <input value={complemento} onChange={handleComplemento} type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-3">
                                                                <label htmlFor="zipCode" className="form-label">Referencia</label>
                                                                <input value={referencia} onChange={handleReferencia} type="text" className="form-control" id="zipCode" name="zipCode" maxLength={6} />
                                                            </div>
                                                            <div className="mb-3 col-md-2">
                                                                <label htmlFor="zipCode" className="form-label">Status</label>
                                                                <select disabled={id?false:true} id="defaultSelect" className="form-select" onChange={handleStatus}>
                                                                    <option>Selecione</option>
                                                                    <option value={'PENDENTE'} selected={status && status === 'PENDENTE' ? 'selected' : null}>Pendente</option>
                                                                    <option value={'ATIVO'} selected={status && status === 'ATIVO' ? 'selected' : null}>Ativo</option>
                                                                    <option value={'INATIVO'} selected={status && status === 'INATIVO' ? 'selected' : null}>Inativo</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <button onClick={() => atualizar()} type="submit" className="btn btn-primary me-2">Salvar</button>
                                                            <button onClick={() => window.history.back()} type="reset" className="btn btn-outline-secondary">Voltar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /Account */}
                                            </div>
                                            {/* <div className="card">
                                        <h5 className="card-header">Delete Account</h5>
                                        <div className="card-body">
                                            <div className="mb-3 col-12 mb-0">
                                                <div className="alert alert-warning">
                                                    <h6 className="alert-heading mb-1">Are you sure you want to delete your account?</h6>
                                                    <p className="mb-0">Once you delete your account, there is no going back. Please be certain.</p>
                                                </div>
                                            </div>
                                            <form id="formAccountDeactivation" onsubmit="return false">
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" type="checkbox" name="accountActivation" id="accountActivation" />
                                                    <label className="form-check-label" htmlFor="accountActivation">I confirm my account
                                                        deactivation</label>
                                                </div>
                                                <button type="submit" className="btn btn-danger deactivate-account">Deactivate Account</button>
                                            </form>
                                        </div>
                                    </div> */}
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}